import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Toast } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/rn-toast.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Toast: Toast }}
        code={snippet}
        platform="react-native"
        gitHubLink="notifications/toast"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines the type of toast.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="text" types={['string', 'element']}>
            <Text>The text that appears in the toast.</Text>
          </PropListItem>

          <PropListItem name="action" types={['shape']}>
            <Text>A subtle button that shows beneath the toast message.</Text>
            <List type="unordered">
              <li>
                <code>text</code> - the text to display in the button
              </li>
              <li>
                <code>onActionPress</code> - func to pass in to handle button
                click
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this toast in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the toast.
            </Text>
          </PropListItem>

          <PropListItem name="onClosePress" types={['func']}>
            <Text>Responds to the toast being dismissed.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
